import type { ApiResult } from "@/types/api";
import FingerprintJS from "@/composables/deepLogic/fingerprint.js";
import axios, { Axios } from "axios";
import { TypedEvent } from "@/composables/deepLogic/TypedEvent";
import type { userCredentials } from "@/types/sign";
import { storeToRefs } from "pinia";
import { useUserTokenStore } from "@/stores/useUserTokenStore";
import { useCacheStore } from "@/stores/useCacheStore";
import type { GenericApiResult } from "@/types/api";
import { usevalidationErrorStore } from "@/stores/useValidationErrorStore";
import { useCommonStore } from "@/stores/useCommonStore";
import i18n from "@/composables/i18n";
import {usePersistedCommonStore} from "@/stores/usePersistedCommonStore";

//'content-type': 'application/x-www-form-urlencoded'
abstract class BaseRequest extends TypedEvent<any> {
  private axios_instance: Axios;
  protected fp;
  protected userStore = useUserTokenStore();
  protected cacheStore = useCacheStore();
  private _lastRequest4Refresh;
  protected constructor (fingerPrint) {
    super();
    this.fp = fingerPrint;
    const { errors } = usevalidationErrorStore();
    const cmonStore = useCommonStore();
    const {gatewayService,onAuthConnectionErrorFlag} = storeToRefs(usePersistedCommonStore());
    let recallRequest = false;
    let recallRequestResponse = null;
    this.axios_instance = axios.create({//
      baseURL:  "https://panel.mehranbit.com/api",
      headers: {
        fp: fingerPrint
      },
      onUploadProgress: (progressEvent) =>
          this.emit({
            event: "onUploadProgress",
            value: Math.floor((progressEvent.loaded / progressEvent.total) * 100),
          }),
    });
    this.axios_instance.interceptors.request.use((config) => {
      let e = Object.keys(errors);
      if (e.length !== 0) {
        cmonStore.setRedToastMessage(errors[e[0]]);
        throw false;
      } else {
        return config;
      }
    });
    this.axios_instance.interceptors.response.use(
        async (response) => {
          if (response.data.status == "TOKEN_TIME_OUT") {
            await this.refreshToken(response.config);
            while (true) {
              if (recallRequest) {
                recallRequest = false;
                return recallRequestResponse;
              }
            }
          } else if (response.data.status == "NEW_TOKEN") {
            this.userStore.setCredentials(response.data.data.data);
            const updatedRequest = {
              ...this._lastRequest4Refresh,
              headers: {
                ...this._lastRequest4Refresh.headers,
                "AT-TOKEN": response.data.data.data.token,
              },
            };
            recallRequestResponse = await this.axios_instance.request(updatedRequest); // Promise.resolve(this.axios_instance.request(updatedRequest));
            recallRequest = true;
          } else if (response.data.status === "UNAUTHORIZED") {
            if(!onAuthConnectionErrorFlag.value){
              setTimeout(()=>{
                onAuthConnectionErrorFlag.value = true;
                location.reload();
              },1500);
            }
            else if(response.config.headers.has('pay')){
              alert(i18n.global.t('errorAuthonPay'));
              location.href = "/user/order-history";
            }
            else {
              gatewayService.value = {
                serviceName: '',
                trackId: 0
              }
              alert(i18n.global.t('need_relogin'));
              onAuthConnectionErrorFlag.value = false;
              location.href = "/auth/login";
            }
          } else {
            return response;
          }
        },
        (error) => {
          //"ERR_NETWORK" << for cors
          cmonStore.setRedToastMessage(i18n.global.t('errNetwork'),5000);
          if (error.response?.data?.message === "Could not decrypt the data.") {
            alert("Could not decrypt the data.");
            // this.refreshToken(error.config);
            //location.href = "/auth/login";
          } else {
            console.error("Error fetching data UseRequest");
          }
          return Promise.reject(error);
        },
    );

    console.log("axios Inited");
  }
  private async refreshToken(config) {
    const { user_credentials } = storeToRefs(this.userStore);
    this._lastRequest4Refresh = config;
    const resRtoken = await this.axios_instance.post(
        "/auth/re_auth",
        {
          "AT-TOKEN": user_credentials.value.token,
          refresh_token: user_credentials.value.refresh_token,
        },
        {
          headers: {
            "AT-TOKEN": user_credentials.value.token,
          },
        },
    );
  }
  public async get(url, headers = {}): ApiResult {
    const res = await this.axios_instance.get(url, headers);
    if (res != undefined) {
      return {
        success: res.data.status === "OK",
        code: res.data.status,
        des: res.data.des,
        data: res.data.data,
      };
    }
  }
  public async post(url, data, headers = {}): ApiResult {
    const res = await this.axios_instance.post(url, data, headers);
    return {
      success: res.data.status === "OK",
      code: res.data.status,
      des: res.data.des,
      data: res.data.data,
    };
  }

  public async upload(url, data, headers = {}, config): ApiResult {
    const res = await this.axios_instance.post(url, data, headers, config);
    return {
      success: res.data.status === "OK",
      code: res.data.status,
      des: res.data.des,
      data: res.data.data,
    };
  }
  public async rowpost(url, data, headers = {}) {
    const res = await this.axios_instance.post(url, data, headers);
    return res.data;
  }
  public validateError() {}
}
export namespace UseRequest {
  export class Request extends BaseRequest implements MehranBitAppRequest {
    private cache;
    public constructor(fingerPrint) {
      super(fingerPrint);
    }
    public getFp(): String {
      return this.fp;
    }

    private fillSignedHeaders(headers = {}) {
      const { user_credentials } = storeToRefs(this.userStore);
      headers = {
        headers: {
          ...headers,
          "AT-TOKEN": user_credentials.value.token,
        },
      };
      return headers;
    }
    clearSign(): void {}

    async signedGet<T>(url, cache: false, headers: {}): Promise<GenericApiResult<T>> {
      if (cache) {
        const cachedData = this.cacheStore.get(url);
        if (cachedData === null) {
          console.log("caching..");
          const data = await this.get("user" + url, this.fillSignedHeaders(headers));
          this.cacheStore.add(url, data);
          return data;
        } else {
          console.log("from cached");
          return cachedData;
        }
      } else {
        return this.get("user" + url, this.fillSignedHeaders(headers));
      }
    }
    signedUpload<T>(url, data: FormData, headers: {}): Promise<GenericApiResult<T>> {
      let uploadheaders = this.fillSignedHeaders(headers);
      return this.post("user" + url, data, uploadheaders);
    }
    signedPost<T>(url, data, headers: {}): Promise<GenericApiResult<T>> {
      return this.post("user" + url, data, this.fillSignedHeaders(headers));
    }

    signedPostRow(url, data, headers: {}) {
      return this.post(url, data, this.fillSignedHeaders(headers));
    }
    signedPostFormData<T>(url, data, headers: {}): Promise<GenericApiResult<T>> {
      let formData = new FormData();
      Object.entries(data).forEach(([key, value]) => {
        formData.delete(key);
        formData.append(key, value);
      });
      // for (var pair of formData.entries()) {
      //   console.log(pair[0] + ", " + pair[1]);
      // }
      return this.signedUpload(url, formData, this.fillSignedHeaders(headers));
    }
    signedPostDT(url, packet) {
      return this.rowpost(
          url,
          packet,
          this.fillSignedHeaders({
            DT: true,
            "Content-Type": "application/json",
          }),
      );
    }
    unsignedPost<T>(url, data): Promise<GenericApiResult<T>> {
      return this.post("user" + url, data, {});
    }

    initAndSaveSign(input: userCredentials): void {
      this.userStore.setCredentials(input);
    }

    delCache(url):void{
      this.cacheStore.deleteCache(url);
    }
  }
}

export default UseRequest;
