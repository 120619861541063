<template>
  <div class="mx-auto min-vh-100 d-flex flex-column justify-content-between">
    <div class="curvyHeader ioscurvyHeader">
      <router-link v-if="['pm-buy'].includes(route.name)" :to="{ name: 'order-history',params:{factor_type_query:FactorTypes.BUY_PERFECTMONEY} }" class="position-absolute end-0 py-3 px-4">
        <img src="@/assets/images/svg/text_white.svg" class="history" />
      </router-link>
      <router-link v-else-if="['pm-sell'].includes(route.name)" :to="{ name: 'order-history',params:{factor_type_query:FactorTypes.SELL_PERFECTMONEY} }" class="position-absolute end-0 py-3 px-4">
        <img src="@/assets/images/svg/text_white.svg" class="history" />
      </router-link>
      <router-link v-else-if="['bills'].includes(route.name)" :to="{ name: 'order-history',params:{factor_type_query:FactorTypes.GHABZINO} }" class="position-absolute end-0 py-3 px-4">
        <img src="@/assets/images/svg/text_white.svg" class="history" />
      </router-link>
      <router-link v-else-if="['giftcard'].includes(route.name)" :to="{ name: 'order-history',params:{factor_type_query:FactorTypes.GIFTCARD} }" class="position-absolute end-0 py-3 px-4">
        <img src="@/assets/images/svg/text_white.svg" class="history" />
      </router-link>
<!--      <router-link v-else-if="route.name === 'buy-crypto'" :to="{ name: 'order-history',params:{factor_type_query:FactorTypes.BUY_CRYPTO},query:{symbol:$route.params.symbol} }" class="position-absolute end-0 top-0 py-3 px-4">-->
<!--        <img src="@/assets/images/svg/text_white.svg" class="history" />-->
<!--      </router-link>-->
<!--      <router-link v-else-if="route.name === 'sell-crypto'" :to="{ name: 'order-history',params:{factor_type_query:FactorTypes.SELL_CRYPTO},query:{symbol:$route.params.symbol} }" class="position-absolute end-0 top-0 py-3 px-4">-->
<!--        <img src="@/assets/images/svg/text_white.svg" class="history" />-->
<!--      </router-link>-->
      <div class="position-absolute end-0 top-0 py-3 px-4" v-if="route.name === 'wallet-withdraw'">
        <router-link :to="{ name: 'wallet_index' }">
          <img class="position-relative history" src="../assets/images/svg/text.svg" width="20" height="20" />
        </router-link>
        <img class="position-relative history mx-4" src="../assets/images/svg/clockIcon.svg" width="20" @click="cmonStorage.OpenWdTimeLine()" />
      </div>

      <a v-if="isSellCryptoStateAvailable" @click="openLastDepositState" class="position-absolute end-0 py-3 px-4">
        <img src="@/assets/images/svg/sell_top.svg" class="history" />
      </a>
      <a v-if="['wallet-offline-index','order-history-details'].includes(route.name)" @click="reloadOrderHistory" class="position-absolute end-0 py-3 px-4">
        <img src="@/assets/images/svg/Refresh.svg" class="history" />
      </a>
      <div>
        <img class="float-start position-relative" src="../assets/images/svg/back.svg" alt="back icon" oncontextmenu="return false;" onclick="history.back()" />
        <router-link to="/">
          <img class="float-start mx-4 position-relative" src="../assets/images/svg/homeLight.svg" oncontextmenu="return false;" />
        </router-link>
      </div>
      <router-link
        class="position-absolute end-0 py-3 px-4"
        :to="{ name: 'roadmap' }"
        v-if="['identityInfo', 'uploadLv1-index', 'uploadLv1-nationalcard', 'uploadLv1-video-selfi', 'uploadLv1-birthdaycard', 'birthdayid-mode', 'contactInfo'].includes(route.name)">
        <img class="position-relative" src="../assets/images/svg/Map.svg" alt="map icon" />
      </router-link>

      <h2 class="position-absolute end-0 start-0 m-auto mb-0 text-white text-center">
        <span v-if="route.name === 'view-level'">
          {{ $t("userLevelNum", [userDashboardData.user_level]) }}
        </span>
        <span v-else-if="route.name === 'all-levels'">
          {{ $t("comparisonOFlevels") }}
        </span>
        <span v-else-if="route.name === 'first-time-auth'">
          {{ $t("startAuth") }}
        </span>
        <span v-else-if="route.name === 'terms'">
          {{ $t("rule") }}
        </span>
        <span v-else-if="route.name === 'contactInfo'">
          {{ $t("confirmContantInfo") }}
        </span>
        <span v-else-if="route.name === 'uploadLv1-nationalcard' || route.name === 'uploadLv1-birthdaycard'">
          {{ $t("verificationIdentityInfo") }}
        </span>
        <span v-else-if="route.name === 'roadmap'">
          {{ $t("steps") }}
        </span>
        <span v-else-if="['identity-info','uploadLv1-idcard','uploadLv1-birthdaycard','uploadLv1-index','birthdayid-mode'].includes(route.name)">
          {{ $t("verificationIdentityInfo") }}
        </span>
        <span v-else-if="route.name === 'googleauthenticate'">
          {{ $t("activationGa") }}
        </span>
        <span v-else-if="route.name === 'levelup'">
          <span v-if="userDashboardData.user_level === 1">
            {{ $t("levelup2") }}
          </span>
          <span v-if="userDashboardData.user_level === 2">
            {{ $t("levelup3") }}
          </span>
          <span v-if="userDashboardData.user_level === 3">
            {{ $t("levelup4") }}
          </span>
        </span>
        <span v-else-if="route.name === 'bankcards-index'">
          {{ $t("managementBankAccounts") }}
        </span>
        <span v-else-if="route.name === 'bankcards-add'">
          {{ $t("addNewCard") }}
        </span>
        <span v-else-if="route.name === 'bankcards-transactions'">
          {{ $t("viewTransactions") }}
        </span>
        <span v-else-if="route.name === 'security-index'">
          {{ $t("security") }}
        </span>
        <span v-else-if="route.name === 'security-changepassword'">
          {{ $t("changePassword") }}
        </span>
        <span v-else-if="route.name === 'order-history-details'">
          {{ $t("details") }}
        </span>
        <span v-else-if="route.name === 'support-index'">
          {{ $t("support") }}
        </span>
        <span v-else-if="route.name === 'support-add-ticket'">
          {{ $t("createNewTicket") }}
        </span>
        <span v-else-if="route.name === 'support-tickets'">
          {{ $t("allTicket") }}
        </span>
        <span v-else-if="route.name === 'support-view-ticket'">
          {{ $t("ticketID", [cmonStorage.getticketId]) }}
        </span>
        <span v-else-if="route.name === 'wallet-wd-request'">
          {{ $t("details") }}
        </span>
        <span v-else-if="route.name === 'wallet-deposit'">
          {{ $t("moneyIncrease") }}
        </span>
        <span v-else-if="route.name === 'wallet-withdraw'">
          {{ $t("withdrawalRequest") }}
        </span>
        <span v-else-if="route.name === 'wallet-withdraw'">
          {{ $t("withdrawalRequest") }}
        </span>
        <span v-else-if="route.name === 'notification-index'">
          {{ $t("notifyMessages") }}
        </span>
        <span v-else-if="route.name === 'onlinepayment'">
          {{ $t("foreignCurrencyPaymentOrder") }}
        </span>
        <span v-else-if="route.name === 'checkout'">
          {{ $t("invoice") }}
        </span>
        <span v-else-if="route.name.toString().indexOf('bill') !== -1">
          {{ $t("payingBills") }}
        </span>
        <span v-else-if="['car-violation'].includes(route.name)">
          {{ $t("violation") }}
        </span>
        <span v-else-if="route.name === 'referral'">
          {{ $t("earnMoney") }}
        </span>
        <span v-else-if="route.name === 'pm-buy'">
          {{ $t("buyPerfectMoney") }}
        </span>
        <span v-else-if="route.name === 'pm-sell'">
          {{ $t("sellingPerfectMoney") }}
        </span>
        <span v-else-if="route.name === 'sell-crypto'">
          {{ $t("sTitle",[cmonStorage.b_s_crypto_title]) }}
        </span>
        <span v-else-if="route.name === 'buy-crypto'">
          {{ $t("bTitle",[cmonStorage.b_s_crypto_title]) }}
        </span>
        <span v-else-if="route.name === 'giftcard-view'">
          {{ $t("buyGiftCard") }}
        </span>
        <span v-else-if="route.name === 'security-history'">
          {{ $t("loginHistory") }}
        </span>
        <span v-else-if="route.name === 'giftcard'">
          {{ $t("giftcard") }}
        </span>
        <span v-else-if="route.name === 'order-history'">
          {{ $t("orderRecords") }}
        </span>
        <span v-else-if="route.name === 'onlinepayment' || route.name === 'onlinepayment-submit'">
          {{ $t("foreignCurrencyPaymentOrder") }}
        </span>
        <span v-else-if="route.name === 'car-violation-viewall'">
          {{ $t("viollationAllCarTitle") }}
        </span>
        <span v-else-if="['car-violation-roles','car-violation-viewall','plaque-selector'].includes(route.name) && carData.view_mode === 'all'">
          {{ $t("viollationAllCarTitleCheck") }}
        </span>
        <span v-else-if="['car-violation-roles','car-violation-viewall','plaque-selector','car-violation-viewdetails'].includes(route.name) && carData.view_mode !== 'all'">
          {{ $t("viollationDetailsCarTitleCheck") }}
        </span>
        <span v-else-if="route.name === 'car-violation-index'">
          {{ $t("carCheckTitle") }}
        </span>
        <span v-else-if="route.name==='market-currency'">
          {{cmonStorage.b_s_crypto_title}}
        </span>
        <span v-else-if="route.name==='identityInfo'">
          {{$t('verificationIdentityInfo')}}
        </span>
        <span v-else-if="route.name==='uploadLv1-video-selfi'">
          {{$t('uploadVideoSelfi')}}
        </span>
        <span v-else-if="route.name==='wallets-index'">
          {{$t('walletIndexTitle')}}
        </span>
        <span v-else-if="route.name==='substitute'">
          {{$t('substitute.header',[cmonStorage.b_s_crypto_title])}}
        </span>
        <span v-else-if="route.name==='payeer-buy'">
          {{$t('payeer.buy_payeer')}}
        </span>
        <span v-else-if="route.name==='payeer-sell'">
          {{$t('payeer.sell_payeer')}}
        </span>
        <span v-else-if="route.name==='wallet-offline-index'">
          {{$t('offlinedeposit.layoutTitleHistory')}}
        </span>
        <span v-else-if="route.name==='wallet-offline-submit'">
          {{$t('offlinedeposit.layoutTitleSubmit')}}
        </span>
      </h2>
    </div>
    <RouterView v-slot="{ Component }" >
      <Suspense>
        <Suspense timeout="0">
          <template #default>
            <component :is="Component" :key="routeKey" class="iosBody"/>
          </template>
          <template #fallback>
            <div v-if="route.name === 'contactInfo'">
              <contact-info-loading />
            </div>
            <div v-else>
              <basic-loading />
            </div>
          </template>
        </Suspense>
      </Suspense>
    </RouterView>
    <!--    <suspense timeout="0">-->
    <!--      <template #default>-->
    <!--        <slot />-->
    <!--      </template>-->
    <!--      <template #fallback>-->
    <!--        <contact-info-loading />-->
    <!--      </template>-->
    <!--    </suspense>-->
  </div>
</template>

<script>
import {computed, inject, nextTick, ref} from "vue";
import { storeToRefs } from "pinia/dist/pinia";
import useUserDataStore from "@/stores/useUserDataStore";
import {useRoute, useRouter} from "vue-router";
import ContactInfoLoading from "@/views/loadings/contactInfo-loading.vue";
import BasicLoading from "@/views/loadings/basicLoading.vue";
import { useCommonStore } from "@/stores/useCommonStore";
import {usePersistedCommonStore} from "@/stores/usePersistedCommonStore";
import { FactorTypes } from "@/types/signed/FactorDetails";
import {useCarViolationStore} from "@/stores/useCarViolationStore";
export default {
  name: "titlepanelLayout",
  components: { BasicLoading, ContactInfoLoading },
  setup() {
    const { userDashboardData } = storeToRefs(useUserDataStore().useDashboardStore());
    const {sellCryptoState} = storeToRefs(usePersistedCommonStore());
    const { carData } = storeToRefs(useCarViolationStore());
    const cmonStorage = useCommonStore();
    const route = useRoute();
    const routeKey = ref(0);
    const isSellCryptoStateAvailable = computed(()=>{
      if(route.name==='sell-crypto' && sellCryptoState.value.state){
        return true;
      }else{
        return false;
      }
    })
    const openLastDepositState = ()=>{
      sellCryptoState.value.clickCounter++;
    }
    const reloadOrderHistory = ()=>{
      routeKey.value++;
    }
    document.addEventListener("visibilitychange", (ev)=> {
          if(route.name==="wallet-deposit"){
            const sts = document["hidden"] ? "hidden" : "visible";
            if (sts === "visible") {
              //router.push({name:'wallet_index'})
            }
          }
        },
        false,
    );

    return {
      carData,
      FactorTypes,
      userDashboardData,
      route,
      cmonStorage,
      isSellCryptoStateAvailable,
      openLastDepositState,
      reloadOrderHistory,
      routeKey
    };
  },
};
</script>

<style scoped>
.rmap {
  position: absolute;
  top: 20px;
}
</style>
