<template>
  <div class="module warning" v-if="popupPushNotification !== null">
    <div class="backDrop"></div>
    <div class="p-3 module-content">
      <h6 class="lg-mainColor-text fw-bold border-bottom py-3 d-flex">
        <img src="@/assets/images/svg/warning.svg" width="20" class="mx-1" />
        <p>{{ popupPushNotification.title }}</p>
      </h6>
      <p class="md-text text-justify">
        {{ popupPushNotification.body }}
      </p>
      <div class="d-flex align-items-center justify-content-between mt-3">
        <button class="mainButton mx-1" @click="closeModal">{{ $t("iRealized") }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { storeToRefs } from "pinia";
import { usePersistedCommonStore } from "@/stores/usePersistedCommonStore";

export default {
  name: "popupPushModal",
  setup() {
    const { popupPushNotification } = storeToRefs(usePersistedCommonStore());
    const closeModal = () => {
      popupPushNotification.value = null;
    };
    return {
      closeModal,
      popupPushNotification,
    };
  },
};
</script>

<style scoped></style>
